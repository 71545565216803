'use client';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Card, Button, Stack, Typography, Box } from '@mui/material';
import { spaRoutePaths } from '@/src/utils/spaRoutePaths';
import { TaskHeader } from './RecentTasksTable';
export const NoTasksFound = () => {
  return <Box sx={{
    mb: 3
  }} data-sentry-element="Box" data-sentry-component="NoTasksFound" data-sentry-source-file="NoTasksFound.tsx">
      <TaskHeader title="Recent tasks" linkText="See all" linkHref={spaRoutePaths.tasks.index()} data-sentry-element="TaskHeader" data-sentry-source-file="NoTasksFound.tsx" />

      <Card sx={{
      p: 3,
      borderRadius: 1,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)'
    }} data-sentry-element="Card" data-sentry-source-file="NoTasksFound.tsx">
        <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{
        py: 2
      }} data-sentry-element="Stack" data-sentry-source-file="NoTasksFound.tsx">
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 64,
          height: 64,
          borderRadius: '50%',
          bgcolor: 'primary.4p',
          mb: 1
        }} data-sentry-element="Box" data-sentry-source-file="NoTasksFound.tsx">
            <AssignmentOutlinedIcon sx={{
            fontSize: 32,
            color: 'primaryShared.main'
          }} data-sentry-element="AssignmentOutlinedIcon" data-sentry-source-file="NoTasksFound.tsx" />
          </Box>
          <Typography variant="h6" align="center" data-sentry-element="Typography" data-sentry-source-file="NoTasksFound.tsx">
            No Tasks Found
          </Typography>
          <Typography variant="bodyMedium" color="text.secondary" align="center" sx={{
          maxWidth: 400
        }} data-sentry-element="Typography" data-sentry-source-file="NoTasksFound.tsx">
            You don&apos;t have any tasks yet. Create a new task to get started.
          </Typography>
          <Button variant="contained" color="primaryShared" href={spaRoutePaths.tasks.index()} sx={{
          mt: 1
        }} data-sentry-element="Button" data-sentry-source-file="NoTasksFound.tsx">
            Create Task
          </Button>
        </Stack>
      </Card>
    </Box>;
};