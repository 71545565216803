// common status and statusText cases:
export const QUOTE_STATUS_NEW = 'New';
export const QUOTE_STATUS_IN_PROGRESS = 'In Progress';
export const QUOTE_STATUS_COMPLETE = 'Complete';
export const QUOTE_STATUS_NO_BID = 'No Bid';
// statusText only cases:
export const QUOTE_STATUS_TEXT_EXPIRED = 'Expired';
export const QUOTE_STATUS_TEXT_COMPLETE = 'Complete';
export const QUOTE_STATUS_TEXT_UNKNOWN = 'Unknown';

export type QuoteStatusType =
  | typeof QUOTE_STATUS_NEW
  | typeof QUOTE_STATUS_IN_PROGRESS
  | typeof QUOTE_STATUS_COMPLETE
  | typeof QUOTE_STATUS_NO_BID;

/**
 * In statusText, the status `Complete` can be displayed as Expired or Complete based on the quote's expiration date.
 */
export type QuoteStatusTextType =
  | typeof QUOTE_STATUS_NEW
  | typeof QUOTE_STATUS_IN_PROGRESS
  // TODO: investigate if we should remove the  QUOTE_STATUS_COMPLETE since the value is duplicated in this type.
  | typeof QUOTE_STATUS_COMPLETE
  | typeof QUOTE_STATUS_NO_BID
  | typeof QUOTE_STATUS_TEXT_EXPIRED
  | typeof QUOTE_STATUS_TEXT_COMPLETE
  // TODO: should the Unknown be part of this??
  | typeof QUOTE_STATUS_TEXT_UNKNOWN;

export const PARENT_QUOTES_CHILD_QUOTES_ASSOCIATION_TABLE =
  'quote_childQuotes__quote_parentQuotes';
