import InfoIcon from '@mui/icons-material/Info';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { Chip, Stack, Tooltip } from '@mui/material';
import { EMAIL_STATUS_RECEIVED, EMAIL_STATUS_OPENED, EMAIL_STATUS_CONVERTED, EMAIL_STATUS_BLOCKED, EMAIL_STATUS_OPENED_MULTIPLE_TIMES, EMAIL_STATUS_CLICKED, EMAIL_STATUS_PROCESSING, type EmailStatus } from '@/shared/constants/EmailConstants';
const getChipLabel = (status: EmailStatus) => {
  switch (status) {
    case EMAIL_STATUS_PROCESSING:
      return 'Processing';
    case EMAIL_STATUS_RECEIVED:
      return 'Received';
    case EMAIL_STATUS_OPENED:
    case EMAIL_STATUS_OPENED_MULTIPLE_TIMES:
      return 'Opened';
    case EMAIL_STATUS_CLICKED:
      return 'Clicked';
    case EMAIL_STATUS_CONVERTED:
      return 'Converted';
    case EMAIL_STATUS_BLOCKED:
      return 'Blocked';
    default:
      return null;
  }
};
const getChipIcon = (status: EmailStatus) => {
  switch (status) {
    case EMAIL_STATUS_OPENED_MULTIPLE_TIMES:
      return <Stack direction="row" sx={{
        alignItems: 'center'
      }}>
          <LocalFireDepartmentIcon fontSize="small" color="error" />
          <InfoIcon fontSize="small" sx={{
          color: 'text.primary',
          fontSize: 14
        }} />
        </Stack>;
    case EMAIL_STATUS_BLOCKED:
      return <InfoIcon fontSize="small" sx={{
        color: 'text.primary',
        '&.MuiSvgIcon-root': {
          color: 'text.primary'
        }
      }} />;
    default:
      return undefined;
  }
};
const getChipColors = (status: EmailStatus) => {
  switch (status) {
    case EMAIL_STATUS_PROCESSING:
      return {
        color: 'info.dark',
        backgroundColor: 'info.12p'
      };
    case EMAIL_STATUS_RECEIVED:
      return {
        color: '#784600',
        backgroundColor: 'warning.12p'
      };
    case EMAIL_STATUS_OPENED:
      return {
        color: '#784600',
        backgroundColor: 'warning.30p'
      };
    case EMAIL_STATUS_OPENED_MULTIPLE_TIMES:
      return {
        color: '#784600',
        backgroundColor: 'warning.50p'
      };
    case EMAIL_STATUS_CLICKED:
      return {
        color: '#784600',
        backgroundColor: 'warning.50p'
      };
    case EMAIL_STATUS_CONVERTED:
      return {
        color: '#00501C',
        backgroundColor: '#AFFFB6'
      };
    case EMAIL_STATUS_BLOCKED:
      return {
        color: '#720606',
        backgroundColor: 'error.50p'
      };
    default:
      return undefined;
  }
};
const getTooltipTitle = (status: EmailStatus) => {
  switch (status) {
    case EMAIL_STATUS_BLOCKED:
      return 'Customer didn’t receive the quote because the email was blocked or bounced. Check if the email address is correct.';
    case EMAIL_STATUS_OPENED_MULTIPLE_TIMES:
      return 'This quote was opened multiple times.';
    default:
      return null;
  }
};
type ConversionStatusChipProps = {
  status: EmailStatus;
};
export const ConversionStatusChip = ({
  status
}: ConversionStatusChipProps) => {
  if (!status) {
    return null;
  }
  const label = getChipLabel(status);
  const icon = getChipIcon(status);
  const colors = getChipColors(status);
  const chip = <Chip label={label} icon={icon} sx={{
    backgroundColor: colors?.backgroundColor,
    color: colors?.color
  }} />;
  const tooltipTitle = getTooltipTitle(status);
  if (tooltipTitle) {
    return <Tooltip title={tooltipTitle}>{chip}</Tooltip>;
  }
  return chip;
};