'use client';

import { useQuery } from '@tanstack/react-query';
import { Box, Grid2, Skeleton, Stack, Typography } from '@mui/material';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { Error } from '@/src/components/Error/Error';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { DashboardActions } from './components/DashboardActions';
import { QuickActionCards } from './components/QuickActionCards';
import { RecentConversations } from './components/RecentConversations/RecentConversations';
import { RecentOrdersTable } from './components/RecentOrdersTable/RecentOrdersTable';
import { RecentQuotesTable } from './components/RecentQuotesTable/RecentQuotesTable';
import { RecentTasksTable } from './components/RecentTasksTable/RecentTasksTable';
export const VendorDashboardPage = () => {
  const tenantAlias = useTenantAlias();
  const {
    data: userInfo
  } = useGetCurrentUserInfoRequest();
  const {
    data: dashboardData,
    isError: isDashboardError,
    isLoading: isDashboardLoading
  } = useQuery(spaQueries.dashboard.supplierData(tenantAlias));
  if (isDashboardError) {
    return <Error />;
  }
  if (isDashboardLoading) {
    return <Box sx={{
      p: 2
    }}>
        <Skeleton variant="rectangular" height={250} sx={{
        my: 2
      }} />
        <Skeleton variant="rectangular" height={250} sx={{
        my: 2
      }} />
      </Box>;
  }
  if (!dashboardData) {
    return <Error />;
  }
  const userName = userInfo?.user?.firstName || '';
  return <Box sx={{
    p: 2
  }} data-sentry-element="Box" data-sentry-component="VendorDashboardPage" data-sentry-source-file="VendorDashboardPage.tsx">
      <Stack direction={{
      xs: 'column',
      md: 'row'
    }} spacing={2} sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 3
    }} data-sentry-element="Stack" data-sentry-source-file="VendorDashboardPage.tsx">
        <Typography variant="h4" sx={{
        fontWeight: 600
      }} data-sentry-element="Typography" data-sentry-source-file="VendorDashboardPage.tsx">
          Hello {userName}!
        </Typography>
        <DashboardActions data-sentry-element="DashboardActions" data-sentry-source-file="VendorDashboardPage.tsx" />
      </Stack>
      <Grid2 container spacing={3} data-sentry-element="Grid2" data-sentry-source-file="VendorDashboardPage.tsx">
        <Grid2 size={12} data-sentry-element="Grid2" data-sentry-source-file="VendorDashboardPage.tsx">
          <QuickActionCards data={dashboardData} data-sentry-element="QuickActionCards" data-sentry-source-file="VendorDashboardPage.tsx" />
        </Grid2>
        <Grid2 size={{
        xs: 12,
        lg: 8
      }} data-sentry-element="Grid2" data-sentry-source-file="VendorDashboardPage.tsx">
          <RecentQuotesTable quotes={dashboardData.quotes} quoteCount={dashboardData.quoteCount} data-sentry-element="RecentQuotesTable" data-sentry-source-file="VendorDashboardPage.tsx" />
          <RecentOrdersTable orders={dashboardData.orders} orderCount={dashboardData.orderCount} data-sentry-element="RecentOrdersTable" data-sentry-source-file="VendorDashboardPage.tsx" />
        </Grid2>
        <Grid2 size={{
        xs: 12,
        lg: 4
      }} data-sentry-element="Grid2" data-sentry-source-file="VendorDashboardPage.tsx">
          <RecentTasksTable tasks={dashboardData.tasks} taskCount={dashboardData.openTaskCount} data-sentry-element="RecentTasksTable" data-sentry-source-file="VendorDashboardPage.tsx" />
          <RecentConversations conversations={dashboardData.conversations} data-sentry-element="RecentConversations" data-sentry-source-file="VendorDashboardPage.tsx" />
        </Grid2>
      </Grid2>
    </Box>;
};