import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Stack } from '@mui/material';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { CreateNewQuoteDrawer } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
import { CreateTaskDrawer } from '@/src/components/Tasks/TaskForm/components/CreateTaskDrawer';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { spaRoutePaths } from '@/src/utils/spaRoutePaths';
const buttonStyle = {
  color: 'primaryShared.contrastText',
  textDecoration: 'underline'
};
export const DashboardActions = () => {
  const tenantAlias = useTenantAlias();
  const {
    data: spaConfig
  } = useQuery(spaQueries.app.spaConfig(tenantAlias));
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  const openQuoteDrawer = useCallback(() => {
    setActiveDrawer(<CreateNewQuoteDrawer />);
  }, [setActiveDrawer]);
  const openTaskDrawer = useCallback(() => {
    setActiveDrawer(<CreateTaskDrawer />);
  }, [setActiveDrawer]);
  return <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{
    backgroundColor: 'primaryShared.main',
    borderRadius: 80,
    px: 2
  }} data-sentry-element="Stack" data-sentry-component="DashboardActions" data-sentry-source-file="DashboardActions.tsx">
      <Button variant="text" endIcon={<DescriptionIcon />} onClick={openQuoteDrawer} sx={buttonStyle} data-sentry-element="Button" data-sentry-source-file="DashboardActions.tsx">
        Create a quote
      </Button>
      <Button variant="text" endIcon={<SearchIcon />} href={spaConfig?.spWebsite + '/search'} sx={buttonStyle} data-sentry-element="Button" data-sentry-source-file="DashboardActions.tsx">
        Search Parts
      </Button>
      <Button variant="text" endIcon={<PersonSearchIcon />} href={spaRoutePaths.contacts.index()} sx={buttonStyle} data-sentry-element="Button" data-sentry-source-file="DashboardActions.tsx">
        Customer search
      </Button>
      <Button variant="text" endIcon={<AssignmentIcon />} onClick={openTaskDrawer} sx={buttonStyle} data-sentry-element="Button" data-sentry-source-file="DashboardActions.tsx">
        Create a task
      </Button>
    </Stack>;
};