'use client';

import currency from 'currency.js';
import EditIcon from '@mui/icons-material/Edit';
import { Link as MuiLink, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { formatDate } from '@qb/frontend/utils/dateFormatter';
import { IntegrationStatuses } from '@/shared/Constants';
import { BuyerDashboardDataResponse, SupplierDashboardDataResponse } from '@/shared/types/controllers/DashboardControllerTypes';
import { ALL_ORDER_STATUSES } from '@/src/components/Common/Constants';
import { OrderNumber } from '@/src/components/Order/OrderListPage/OrderNumber/OrderNumber';
import { StatusLabel } from '@/src/components/Order/OrderListPage/StatusLabel/StatusLabel';
import { isSupplierOrderData } from '../../utils/isSupplierOrderData';
import { RecentOrdersHeading } from './RecentOrdersHeading';
type DesktopRecentOrdersTableProps = {
  orders: BuyerDashboardDataResponse['orders'] | SupplierDashboardDataResponse['orders'];
  orderCount: number;
};
export const DesktopRecentOrdersTable = ({
  orders,
  orderCount
}: DesktopRecentOrdersTableProps) => {
  const isUnderBreakpointXl = useIsUnderBreakpoint('xl');
  return <Paper sx={{
    border: 'none',
    p: 2,
    mb: 2
  }} data-sentry-element="Paper" data-sentry-component="DesktopRecentOrdersTable" data-sentry-source-file="DesktopRecentOrdersTable.tsx">
      <RecentOrdersHeading orderCount={orderCount} data-sentry-element="RecentOrdersHeading" data-sentry-source-file="DesktopRecentOrdersTable.tsx" />
      <TableContainer sx={{
      px: 0
    }} data-sentry-element="TableContainer" data-sentry-source-file="DesktopRecentOrdersTable.tsx">
        <Table size={isUnderBreakpointXl ? 'small' : 'medium'} data-sentry-element="Table" data-sentry-source-file="DesktopRecentOrdersTable.tsx">
          <TableHead data-sentry-element="TableHead" data-sentry-source-file="DesktopRecentOrdersTable.tsx">
            <TableRow data-sentry-element="TableRow" data-sentry-source-file="DesktopRecentOrdersTable.tsx">
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentOrdersTable.tsx">Order&nbsp;#</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentOrdersTable.tsx">Customer</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentOrdersTable.tsx">Total</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentOrdersTable.tsx">Status</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentOrdersTable.tsx">PO&nbsp;#</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentOrdersTable.tsx">Created</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="DesktopRecentOrdersTable.tsx">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-sentry-element="TableBody" data-sentry-source-file="DesktopRecentOrdersTable.tsx">
            {orders.map(order => {
            const isOrderWithExceptions = isSupplierOrderData(order) && order.sentToIntegration === IntegrationStatuses.INTEGRATION_EXCEPTION;
            return <TableRow hover key={order.id}>
                  <TableCell>
                    <OrderNumber id={order.id} isWithIntegrationExceptions={isOrderWithExceptions} />
                  </TableCell>
                  <TableCell>
                    {isSupplierOrderData(order) ? <MuiLink key={order.id} href={`/order/${order.id}/display`} color="text.secondary">
                        {order.orderedBy.firstName} {order.orderedBy.lastName}
                      </MuiLink> : <MuiLink key={order.id} href={`/order/${order.id}/display`} color="text.secondary">
                        {order.supplierCompanyName}
                      </MuiLink>}
                  </TableCell>
                  <TableCell>{currency(order.total).format()}</TableCell>
                  <TableCell>
                    {isSupplierOrderData(order) ? <>
                        <StatusLabel key={order.id} statusText={isOrderWithExceptions && order.statusText !== ALL_ORDER_STATUSES.declined ? ALL_ORDER_STATUSES.exception : order.statusText} isVisible={true} orderID={order.id} />
                      </> : <StatusLabel key={order.id} statusText={order.statusText} isVisible={true} orderID={order.id} />}
                  </TableCell>
                  <TableCell>{order.poNum || '-'}</TableCell>
                  <TableCell>{formatDate(new Date(order.createdAt))}</TableCell>
                  <TableCell>
                    <IconButton title="Edit" href={`/order/${order.id}/display`}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>;
          })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>;
};