'use client';

import { useMemo, useEffect } from 'react';
import Avatar from 'react-avatar';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import { getSPAApiURLForTenant } from '@qb/frontend/utils/hosts/apiHost';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { GetRequest } from '@qb/httpRequest/Request';
import robot from '@/public/images/Common/zoe.svg';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { useReduxDispatch, useReduxSelector } from '@/src/store/store';
import { addUserProfileImage } from '../../store/reducers/main';

/**
 * @deprecated use `import { randomColorsList } from '@qb/base-theme/randomColorsList';` instead.
 */
export const Colors = ['#d73d32', '#7e3794', '#4285f4', '#67ae3f', '#d61a7f', '#ff4080', '#81efea', '#db51a8', '#ef5807', '#e23db6', '#ffccf6', '#d6dcf0', '#a528d6', '#b770f9', '#53c1e2', '#218de0', '#5a6cce', '#e5da3b', '#4a3799', '#93e884', '#1c4f82', '#f7f25d', '#dd9b66', '#bfdcfc', '#899add', '#289909'];

// Cache
const userPhotoIconWidthHeight = 48;
const MADE_REQUEST_FOR_USER_ID = {};
export default function UserImageIcon(props) {
  const {
    userProfileImages
  } = useReduxSelector(({
    main
  }) => main);
  const theme = useTheme();
  const dispatch = useReduxDispatch();
  const shouldFetch = useMemo(() => {
    return !props.noFetch && props.user.id && typeof props.user.id === 'number';
  }, [props.noFetch, props.user.id]);
  const tenantAlias = useTenantAlias();
  useEffect(() => {
    if (shouldFetch) {
      if (userProfileImages[props.user.id] && props.user.profileImageURL === userProfileImages[props.user.id]) return;
      if (MADE_REQUEST_FOR_USER_ID[props.user.id]) return;
      MADE_REQUEST_FOR_USER_ID[props.user.id] = true;
      GetRequest(`${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/user/${props.user.id}/profileImage`, data => {
        dispatch(addUserProfileImage({
          id: props.user.id,
          url: data.url
        }));
        MADE_REQUEST_FOR_USER_ID[props.user.id] = false;
      });
    }
  }, [shouldFetch, props.user.profileImageURL, tenantAlias, dispatch, props.user.id, userProfileImages]);
  if (props.user.type === 'BOT') {
    let style = {
      color: theme.palette.primary.main,
      width: props.overrideWidth || userPhotoIconWidthHeight,
      height: props.overrideHeight || userPhotoIconWidthHeight
    };
    merge(style, props.style || {});
    return <img src={robot} style={style} title="Quotebeam Bot" alt="Quotebeam Bot" />;
  } else if (shouldFetch && !MADE_REQUEST_FOR_USER_ID[props.user.id] && typeof userProfileImages[props.user.id] === 'undefined') {
    return <Skeleton circle width={props.overrideWidth || userPhotoIconWidthHeight} height={props.overrideHeight || userPhotoIconWidthHeight} />;
  } else if (shouldFetch && userProfileImages[props.user.id]) {
    return <img src={userProfileImages[props.user.id]} title={`${props.user.firstName}${props.user.lastName ? ' ' + props.user.lastName : ''}`} style={{
      maxHeight: props.overrideHeight || userPhotoIconWidthHeight,
      maxWidth: props.overrideWidth || userPhotoIconWidthHeight,
      height: props.overrideHeight || userPhotoIconWidthHeight,
      width: props.overrideWidth || userPhotoIconWidthHeight,
      borderRadius: '50%'
    }} alt={`${props.user.firstName}${props.user.lastName ? ` ${props.user.lastName}` : ''}`} />;
  } else {
    let color = Avatar.getRandomColor(props.user.firstName.charAt(0), Colors);
    return <Avatar title={`${props.user.firstName}${props.user.lastName ? ' ' + props.user.lastName : ''}`} name={props.user.firstName.charAt(0)} value={props.user.firstName.charAt(0)} size={props.overrideWidth || userPhotoIconWidthHeight} textSizeRatio={2} round color={color} />;
  }
}
UserImageIcon.propTypes = {
  user: PropTypes.object.isRequired,
  overrideWidth: PropTypes.number,
  overrideHeight: PropTypes.number,
  overrideFontSize: PropTypes.number,
  style: PropTypes.object,
  noFetch: PropTypes.bool
};