import { Stack, SxProps, Typography } from '@mui/material';
import STATES from '@/shared/States.js';
import { i18nIsoCountries } from '@/shared/countriesList';
import AddressInstance from '@/shared/types/models/AddressInstance';
import ContactInstance from '@/shared/types/models/ContactInstance';
type Address = Pick<AddressInstance, 'id' | 'primary' | 'companyName' | 'personName' | 'street' | 'street2' | 'city' | 'state' | 'zipCode' | 'countryCode'>;
const getCountryName = (countryCode: string) => i18nIsoCountries.getName(countryCode, 'en', {
  select: 'official'
});
export const getStateAbbreviation = (state: string) => {
  if (!state) return state;
  const foundState = STATES.states.find(s => s.name.toLowerCase() === state.toLowerCase());
  return foundState ? foundState.abbreviation : state;
};

/**
 * @example
 * `Quotebeam Support; 5264 Romford Dr, San Jose, CA 95124`
 */
export const formatAddressLong = (address: Address): string => {
  if (!address || !address.street) return '';
  const {
    companyName = '',
    personName = '',
    countryCode,
    street,
    street2,
    city,
    state,
    zipCode
  } = address;
  const formattedCompanyName = companyName && companyName + '; ';
  const formattedPersonName = personName && personName + '; ';
  const formattedStreet2 = street2 && ' ' + street2;
  if (countryCode !== 'USA' && countryCode !== 'US') {
    const countryName = getCountryName(countryCode);
    return `${formattedCompanyName}${formattedPersonName}${countryName}, ${street}${formattedStreet2}, ${city}, ${state} ${zipCode}`;
  } else {
    const abbreviatedState = getStateAbbreviation(state);
    return `${formattedCompanyName}${formattedPersonName}${street}${formattedStreet2}, ${city}, ${abbreviatedState} ${zipCode}`;
  }
};

/**
 * @example
 * `San Jose, CA`
 */
export const formatAddressShort = (address: Address): string => {
  if (!address) return '';
  const {
    countryCode,
    city,
    state
  } = address;
  if (countryCode !== 'USA' && countryCode !== 'US') {
    const countryName = getCountryName(countryCode);
    return `${city}, ${countryName}`;
  } else {
    const abbreviatedState = getStateAbbreviation(state);
    return `${city}, ${abbreviatedState}`;
  }
};

/**
 * @example
 * `5264 Romford Dr
 * San Jose CA, 95124`
 */
export const formatAddressMultiLine = (address: Partial<Address>) => {
  if (!address) return null;
  const {
    countryCode,
    street,
    street2,
    city,
    state,
    zipCode
  } = address;
  const street2Formatted = street2 && ' ' + street2;
  if (countryCode !== 'USA' && countryCode !== 'US') {
    const countryName = countryCode ? getCountryName(countryCode) : '';
    return <>
        <span>
          {street}
          {street2Formatted}
        </span>
        <br />
        <span>{`${city} ${state}, ${zipCode} ${countryName}`}</span>
      </>;
  } else {
    const abbreviatedState = state ? ` ${getStateAbbreviation(state)}` : '';
    return <>
        <span>
          {street}
          {street2Formatted}
        </span>
        <br />
        <span>{`${city}${abbreviatedState}, ${zipCode}`}</span>
      </>;
  }
};

/**
 * Formats an address with city, state, and zip code based on country
 *
 * @example
 * // For US address
 * formatAddressCityStateZip({
 *   city: 'San Jose',
 *   state: 'California',
 *   zipCode: '95124',
 *   countryCode: 'US'
 * });
 * // Returns: "San Jose, CA 95124"
 *
 * @example
 * // For international address
 * formatAddressCityStateZip({
 *   city: 'London',
 *   state: 'England',
 *   zipCode: 'SW1A 1AA',
 *   country: 'United Kingdom'
 * });
 * // Returns: "London, England SW1A 1AA, United Kingdom"
 */
export type AddressOrContactAddress = Partial<Pick<AddressInstance, 'street' | 'street2' | 'countryCode' | 'city' | 'state' | 'zipCode'>> | Partial<Pick<ContactInstance, 'street' | 'street2' | 'country' | 'city' | 'state' | 'zipCode'>>;
export const formatAddressCityStateZipCountryLine = (address: AddressOrContactAddress) => {
  if (!address || !address.city) {
    return null;
  }
  const {
    city,
    state,
    zipCode
  } = address;

  // Handle countryCode
  if ('countryCode' in address) {
    if (isCountryCodeUnitedStates(address.countryCode)) {
      if (!state) {
        return city;
      }
      const stateAbbr = getStateAbbreviation(state);
      return zipCode ? `${city}, ${stateAbbr} ${zipCode}` : `${city}, ${stateAbbr}`;
    }

    // International with countryCode
    if (!state) {
      return city;
    }
    const cityStateZip = `${city}, ${state} ${zipCode || ''}`.trim();
    return address.countryCode ? `${cityStateZip}, ${getCountryName(address.countryCode)}` : cityStateZip;
  }

  // Handle country field
  if ('country' in address && address.country) {
    if (isCountryUnitedStates(address.country)) {
      if (!state) {
        return city;
      }
      const stateAbbr = getStateAbbreviation(state);
      return zipCode ? `${city}, ${stateAbbr} ${zipCode}` : `${city}, ${stateAbbr}`;
    }

    // International with country
    if (!state) {
      return city;
    }
    const cityStateZip = `${city}, ${state} ${zipCode || ''}`.trim();
    return `${cityStateZip}, ${address.country}`;
  }

  // No country info
  if (!state) {
    return city;
  }
  return zipCode ? `${city}, ${state} ${zipCode}` : `${city}, ${state}`;
};
type SxStyles = {
  line1?: SxProps;
  line2?: SxProps;
  line3?: SxProps;
  container?: SxProps;
};
export const formatFullAddress = (address: AddressOrContactAddress, {
  line1 = {},
  line2 = {},
  line3 = {},
  container = {}
}: SxStyles = {}) => {
  if (!address) return null;
  const {
    street,
    street2
  } = address;
  const baseStyles = {
    color: 'text.primary'
  };
  return <Stack spacing={0.5} sx={container} data-sentry-element="Stack" data-sentry-component="formatFullAddress" data-sentry-source-file="formatAddress.tsx">
      {street && <Typography variant="bodySmall" sx={{
      ...baseStyles,
      ...line1
    }}>
          {street}
        </Typography>}
      {street2 && <Typography variant="bodySmall" sx={{
      ...baseStyles,
      ...line2
    }}>
          {street2}
        </Typography>}
      <Typography variant="bodySmall" sx={{
      ...baseStyles,
      ...line3
    }} data-sentry-element="Typography" data-sentry-source-file="formatAddress.tsx">
        {formatAddressCityStateZipCountryLine(address)}
      </Typography>
    </Stack>;
};
const isCountryCodeUnitedStates = (countryCode?: string) => countryCode === 'USA' || countryCode === 'US';
const isCountryUnitedStates = (country?: string) => country === 'USA' || country === 'US' || country === 'United States' || country === 'United States of America';