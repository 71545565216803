/**
 * SPA Routes Utility
 *
 * This utility provides functions to generate URLs for all routes in the SPA.
 * The structure mirrors the pages directory structure, with nested objects for nested routes.
 * Each function takes the necessary parameters (like IDs) to generate the correct URL.
 */
export const spaRoutePaths = {
  // Root routes
  index: () => '/',
  login: () => '/login',
  logout: () => '/logout',
  forgotPassword: () => '/forgotPassword',
  forgotPasswordLinkSent: () => '/forgotPasswordLinkSent',

  // CRM routes
  crm: {
    company: {
      index: (companyID: number) => `/crm/company/${companyID}`,
    },
    contact: {
      index: (contactID: number) => `/crm/contact/${contactID}`,
      // Add any contact sub-routes here
    },
    user: {
      index: (userID: number) => `/crm/user/${userID}`,
      // Add any user sub-routes here
    },
  },

  // Quote routes
  quote: {
    listSupplier: () => '/quote/listSupplier',
    detail: {
      display: (quoteID: number) => `/quote/${quoteID}/display`,
    },
  },

  // Tasks routes
  tasks: {
    index: () => '/tasks',
  },

  // Contacts routes
  contacts: {
    index: () => '/contacts',
    // Add any contacts sub-routes here
  },

  // Conversation routes
  conversation: {
    index: () => '/conversation',
    display: (conversationID: number) =>
      `/conversation/${conversationID}/display`,
    list: () => '/conversation/list',
    // Add any conversation sub-routes here
  },

  // Certificates routes
  certificates: {
    index: () => '/certificates',
    // Add any certificates sub-routes here
  },

  // Inventory and pricing routes
  inventoryAndPricing: {
    index: () => '/inventory-and-pricing',
    // Add any inventory-and-pricing sub-routes here
  },

  // Payment terms routes
  paymentTerms: {
    index: () => '/payment-terms',
    // Add any payment-terms sub-routes here
  },

  // Price catalogs routes
  priceCatalogs: {
    index: () => '/price-catalogs',
    // Add any price-catalogs sub-routes here
  },

  // Invite routes
  invite: {
    index: () => '/invite',
    // Add any invite sub-routes here
  },

  // Order routes
  order: {
    index: () => '/order/list-supplier',
    // Add any order sub-routes here
  },

  // Settings routes
  settings: {
    index: () => '/settings',
    // Add any settings sub-routes here
  },
};
