import { Chip } from '@mui/material';
import { QuoteStatusTextType, QUOTE_STATUS_NEW, QUOTE_STATUS_NO_BID, QUOTE_STATUS_TEXT_EXPIRED, QUOTE_STATUS_TEXT_COMPLETE, QUOTE_STATUS_IN_PROGRESS } from '@/shared/models/QuoteConstants';
import CompleteQuoteStatusIcon from './assets/complete-quote-status-icon.svg';
import ExpiredQuoteStatusIcon from './assets/expired-quote-status-icon.svg';
import InProgressQuoteStatusIcon from './assets/in-progress-quote-status-icon.svg';
import NewQuoteStatusIcon from './assets/new-quote-status-icon.svg';
import NoBidQuoteStatusIcon from './assets/no-bid-quote-status-icon.svg';
export const getQuoteStatusIcon = (statusText: QuoteStatusTextType) => {
  switch (statusText) {
    case QUOTE_STATUS_NEW:
      return NewQuoteStatusIcon;
    case QUOTE_STATUS_NO_BID:
      return NoBidQuoteStatusIcon;
    case QUOTE_STATUS_TEXT_EXPIRED:
      return ExpiredQuoteStatusIcon;
    case QUOTE_STATUS_TEXT_COMPLETE:
      return CompleteQuoteStatusIcon;
    case QUOTE_STATUS_IN_PROGRESS:
      return InProgressQuoteStatusIcon;
    default:
      return null;
  }
};
const getLabelColor = (statusText: QuoteStatusTextType) => {
  switch (statusText) {
    case QUOTE_STATUS_NEW:
      return {
        backgroundColor: '#50B5FF'
      };
    case QUOTE_STATUS_NO_BID:
      return {
        backgroundColor: '#FC5A5A'
      };
    case QUOTE_STATUS_TEXT_EXPIRED:
      return {
        backgroundColor: 'secondary.main'
      };
    case QUOTE_STATUS_TEXT_COMPLETE:
      return {
        backgroundColor: 'success.main'
      };
    case QUOTE_STATUS_IN_PROGRESS:
      return {
        backgroundColor: 'secondary.main'
      };
    default:
      return {
        backgroundColor: '#EBEBEB',
        color: '#000000'
      };
  }
};
const getLabelText = (statusText: QuoteStatusTextType) => {
  switch (statusText) {
    case QUOTE_STATUS_NEW:
      return 'New';
    case QUOTE_STATUS_NO_BID:
      return 'Declined';
    case QUOTE_STATUS_TEXT_EXPIRED:
      return 'Expired';
    case QUOTE_STATUS_TEXT_COMPLETE:
      return 'Complete';
    case QUOTE_STATUS_IN_PROGRESS:
      return 'In Progress';
    default:
      return 'Unknown';
  }
};
type QuoteStatusProps = {
  statusText: QuoteStatusTextType;
};
export const QuoteStatus = ({
  statusText
}: QuoteStatusProps) => {
  const label = getLabelText(statusText);
  const colors = getLabelColor(statusText);
  return <Chip label={label} sx={{
    backgroundColor: colors?.backgroundColor,
    color: colors?.color || 'common.white'
  }} data-sentry-element="Chip" data-sentry-component="QuoteStatus" data-sentry-source-file="QuoteStatus.tsx" />;
};