import React from 'react';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { Box, Button, Grid2, Paper, Stack, Typography } from '@mui/material';
import { SupplierDashboardDataResponse } from '@/shared/types/controllers/spa/SPADashboardControllerTypes';
import { spaRoutePaths } from '@/src/utils/spaRoutePaths';
type QuickActionCardProps = {
  icon: React.ReactNode;
  title: string;
  buttonText: string;
  count: number;
  href: string;
  accentColor?: string;
};
const QuickActionCard = ({
  icon,
  title,
  buttonText,
  count,
  href,
  accentColor = '#5200A3'
}: QuickActionCardProps) => {
  const isZeroCount = count === 0;
  return <Paper elevation={0} sx={{
    p: 3,
    height: '100%',
    bgcolor: 'background.paper',
    position: 'relative',
    overflow: 'hidden'
  }} data-sentry-element="Paper" data-sentry-component="QuickActionCard" data-sentry-source-file="QuickActionCards.tsx">
      <Box sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      width: 6,
      height: '100%',
      bgcolor: isZeroCount ? 'success.main' : accentColor
    }} data-sentry-element="Box" data-sentry-source-file="QuickActionCards.tsx" />

      <Stack spacing={2} height="100%" data-sentry-element="Stack" data-sentry-source-file="QuickActionCards.tsx">
        <Stack direction="row" spacing={2} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="QuickActionCards.tsx">
          <Box sx={{
          color: isZeroCount ? 'success.main' : accentColor,
          '& .MuiSvgIcon-root': {
            fontSize: 32
          }
        }} data-sentry-element="Box" data-sentry-source-file="QuickActionCards.tsx">
            {icon}
          </Box>

          <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="QuickActionCards.tsx">{`${count} ${title}`}</Typography>
        </Stack>

        <Button variant="contained" color="primaryShared" href={href} sx={{
        width: 'fit-content',
        textTransform: 'none',
        mt: 'auto',
        height: 32,
        px: 3,
        py: 1
      }} data-sentry-element="Button" data-sentry-source-file="QuickActionCards.tsx">
          {buttonText}
        </Button>
      </Stack>
    </Paper>;
};
type QuickActionCardsProps = {
  data: SupplierDashboardDataResponse;
};
export const QuickActionCards = ({
  data
}: QuickActionCardsProps) => {
  return <Grid2 container spacing={3} sx={{
    mb: 4
  }} data-sentry-element="Grid2" data-sentry-component="QuickActionCards" data-sentry-source-file="QuickActionCards.tsx">
      <Grid2 size={{
      xs: 12,
      sm: 6,
      md: 3
    }} data-sentry-element="Grid2" data-sentry-source-file="QuickActionCards.tsx">
        <QuickActionCard icon={<ListAltOutlinedIcon />} title="open tasks" buttonText="Show tasks" count={data.openTaskCount} href={spaRoutePaths.tasks.index()} data-sentry-element="QuickActionCard" data-sentry-source-file="QuickActionCards.tsx" />
      </Grid2>

      <Grid2 size={{
      xs: 12,
      sm: 6,
      md: 3
    }} data-sentry-element="Grid2" data-sentry-source-file="QuickActionCards.tsx">
        <QuickActionCard icon={<RequestQuoteOutlinedIcon />} title="incomplete quotes" buttonText="Show quotes" count={data.incompleteQuoteCount} href={spaRoutePaths.quote.listSupplier()} data-sentry-element="QuickActionCard" data-sentry-source-file="QuickActionCards.tsx" />
      </Grid2>

      <Grid2 size={{
      xs: 12,
      sm: 6,
      md: 3
    }} data-sentry-element="Grid2" data-sentry-source-file="QuickActionCards.tsx">
        <QuickActionCard icon={<LocalShippingOutlinedIcon />} title="new orders" buttonText="Show orders" count={data.newOrderCount} href={spaRoutePaths.order.index()} accentColor={data.newOrderCount === 0 ? 'success.main' : undefined} data-sentry-element="QuickActionCard" data-sentry-source-file="QuickActionCards.tsx" />
      </Grid2>

      <Grid2 size={{
      xs: 12,
      sm: 6,
      md: 3
    }} data-sentry-element="Grid2" data-sentry-source-file="QuickActionCards.tsx">
        <QuickActionCard icon={<ChatOutlinedIcon />} title="new conversations" buttonText="Show chats" count={data.newConversationCount} href={spaRoutePaths.conversation.list()} data-sentry-element="QuickActionCard" data-sentry-source-file="QuickActionCards.tsx" />
      </Grid2>
    </Grid2>;
};