import { Box, Card, Stack, Typography } from '@mui/material';
import { formatDate } from '@qb/frontend/utils/dateFormatter';
import { SupplierDashboardDataResponse } from '@/shared/types/controllers/spa/SPADashboardControllerTypes';
import { getTaskTypeIcon } from '@/src/components/Tasks/TaskListPage/utils/taskUtils';
import { spaRoutePaths } from '@/src/utils/spaRoutePaths';
import { NoTasksFound } from './NoTasksFound';
export type RecentTasksTableProps = {
  tasks: SupplierDashboardDataResponse['tasks'];
  taskCount?: number;
};
type TaskCardProps = {
  task: SupplierDashboardDataResponse['tasks'][number];
};
type TaskHeaderProps = {
  title: string;
  linkText: string;
  linkHref: string;
};
export const TaskHeader = ({
  title,
  linkText,
  linkHref
}: TaskHeaderProps) => <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
  mb: 2
}} data-sentry-element="Stack" data-sentry-component="TaskHeader" data-sentry-source-file="RecentTasksTable.tsx">
    <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="RecentTasksTable.tsx">{title}</Typography>
    <Typography variant="bodyMediumPrimary" component="a" href={linkHref} sx={{
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }} data-sentry-element="Typography" data-sentry-source-file="RecentTasksTable.tsx">
      {linkText}
    </Typography>
  </Stack>;
const isToday = (dateString: string | Date): boolean => {
  const today = new Date();
  const dueDate = typeof dateString === 'string' ? new Date(dateString) : dateString;
  return dueDate.toDateString() === today.toDateString();
};
const TaskIcon = ({
  task
}: {
  task: SupplierDashboardDataResponse['tasks'][number];
}) => {
  const isDueToday = task.dueDate ? isToday(task.dueDate) : false;
  return getTaskTypeIcon(task.type, {
    circleSx: isDueToday ? {
      bgcolor: 'error.main',
      flexShrink: 0
    } : undefined,
    iconSx: isDueToday ? {
      color: 'white'
    } : undefined
  });
};
const TaskCard = ({
  task
}: TaskCardProps) => {
  const isDueToday = task.dueDate ? isToday(task.dueDate) : false;
  return <Card sx={{
    p: 2,
    mb: 2,
    ...(isDueToday && {
      bgcolor: 'error.4p'
    })
  }} data-sentry-element="Card" data-sentry-component="TaskCard" data-sentry-source-file="RecentTasksTable.tsx">
      <Stack direction="row" spacing={2} alignItems="flex-start" data-sentry-element="Stack" data-sentry-source-file="RecentTasksTable.tsx">
        <TaskIcon task={task} data-sentry-element="TaskIcon" data-sentry-source-file="RecentTasksTable.tsx" />
        <Box sx={{
        flexGrow: 1
      }} data-sentry-element="Box" data-sentry-source-file="RecentTasksTable.tsx">
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" data-sentry-element="Stack" data-sentry-source-file="RecentTasksTable.tsx">
            <Box data-sentry-element="Box" data-sentry-source-file="RecentTasksTable.tsx">
              <Typography variant="h6" sx={{
              mb: 1
            }} data-sentry-element="Typography" data-sentry-source-file="RecentTasksTable.tsx">
                {task.title}
              </Typography>
              <Typography variant="bodyMedium" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="RecentTasksTable.tsx">
                {task.description}
              </Typography>
            </Box>
          </Stack>
          {isDueToday && task.dueDate && <Stack direction="row" justifyContent="flex-end" sx={{
          mt: 1
        }}>
              <Typography variant="bodySmallError">
                <Box component="span" sx={{
              fontWeight: 600,
              mr: 1
            }}>
                  Due date:
                </Box>
                {formatDate(String(task.dueDate))}
              </Typography>
            </Stack>}
        </Box>
      </Stack>
    </Card>;
};
export const RecentTasksTable = ({
  tasks
}: RecentTasksTableProps) => {
  if (!tasks || tasks.length === 0) {
    return <NoTasksFound />;
  }
  return <Box sx={{
    mb: 3
  }} data-sentry-element="Box" data-sentry-component="RecentTasksTable" data-sentry-source-file="RecentTasksTable.tsx">
      <TaskHeader title="Recent tasks" linkText="See all" linkHref={spaRoutePaths.tasks.index()} data-sentry-element="TaskHeader" data-sentry-source-file="RecentTasksTable.tsx" />
      {tasks.map(task => <TaskCard key={task.id} task={task} />)}
    </Box>;
};